'use client';

import React from 'react';
import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import { Locale } from '../../../i18n-config';
import dynamic from 'next/dynamic';

import Section11 from '@/components/home/Section11/Section11';

import SiteCustomerSupportComponent from '@/components/home/SiteCustomerSupportComponent';
import SiteSocialNetworkComponent from '@/components/home/SiteSocialNetworkComponent';
import Section4 from '@/components/home/Section4/Section4';
import Section5 from '@/components/home/Section5/Section5';
import Section10 from '@/components/home/Section10/Section10';
import CompareSection from '@/components/home/CompareSection/CompareSection';
import Section3 from '@/components/home/Section3/Section3';

const Section2 = dynamic(() => import('@/components/home/Section2/Section2'), { ssr: false });

const Client = () => {
  const params = useParams<{ lang?: string }>();

  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');

  return (
    <div className="home-page bg-eighteenColor">
      <Section11 t={t} params={params} />
      <Section2 t={t} />
      <CompareSection t={t} />
      <Section3 t={t} />
      {/* <Section6 t={t} /> */}
      <Section4 t={t} />
      <Section5 t={t} />
      <Section10 t={t} />
      <SiteCustomerSupportComponent />
      <SiteSocialNetworkComponent />
    </div>
  );
};

export default Client;
