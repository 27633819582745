'use client';

import { TFunction } from 'i18next';
import Image from 'next/image';
import Link from 'next/link';
import { useParams, useRouter } from 'next/navigation';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { BiCheck } from 'react-icons/bi';

const Section4 = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const params = useParams();
  const router = useRouter();

  const items = [
    {
      imgSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/24022709580073_Home_2.png',
      videoSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/24030408595429_Chatbot.mp4',
      title: t('Home/Section4/key1').toString(),
      description: t('Home/Section4/key1-2').toString(),
      features: [
        t('Home/Section4/key1-3').toString(),
        t('Home/Section4/key1-4').toString(),
        t('Home/Section4/key1-5').toString(),
      ],
      button: { title: t('Home/Section4/key1-6').toString(), link: 'plans' },
    },
    {
      imgSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/2402270958004_Home_3.png',
      videoSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/2402281101415_louise_video_chatbot.mp4',
      title: t('Home/Section4/key2').toString(),
      description: t('Home/Section4/key2-2').toString(),
      features: [
        t('Home/Section4/key2-3').toString(),
        t('Home/Section4/key2-4').toString(),
        t('Home/Section4/key2-5').toString(),
      ],
      button: { title: t('Home/Section4/key2-6').toString(), link: 'plans' },
    },
    {
      imgSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/24022709580054_Home_4.png',
      videoSrc: 'https://cdn.1cdn.app/application/LOUISEBOT/24030408595450_Assistant_with_AI.mp4',
      title: t('Home/Section4/key3').toString(),
      description: t('Home/Section4/key3-2').toString(),
      features: [
        t('Home/Section4/key3-3').toString(),
        t('Home/Section4/key3-4').toString(),
        t('Home/Section4/key3-5').toString(),
      ],
      button: { title: t('Home/Section4/key3-6').toString(), link: 'plans' },
    },
  ];

  const handleGoToVideoChatBot = (index: number) => {
    switch (index) {
      case 0:
        router.push(`/${params?.lang ?? 'en-US'}/text-chatbot`);
        break;
        break;
      case 1:
        router.push(`/${params?.lang ?? 'en-US'}/video-chatbot`);
        break;
      case 2:
        router.push(`/${params?.lang ?? 'en-US'}/ai-chatbot`);
        break;
      default:
        break;
    }
  };

  return (
    <section className="sec-4">
      <Container fluid>
        <h2>{t('Home/Section4/key0').toString()}</h2>
        <Row className="g-3 justify-content-center">
          {items.map((item, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4">
              <div className="box">
                <div
                  className={`image-wrapper position-relative tw-cursor-pointer`}
                  onClick={() => handleGoToVideoChatBot(index)}
                >
                  {item.videoSrc ? (
                    <video src={item.videoSrc} autoPlay loop muted playsInline className="tw-w-full tw-h-auto" />
                  ) : (
                    <Image src={item.imgSrc} fill alt={item.title} title={item.title} />
                  )}
                </div>

                <div className="content-wrapper">
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                  <ul className="sm:tw-my-4 tw-my-4">
                    {item.features.map((feature, index) => (
                      <li key={index}>
                        <span>
                          <BiCheck size={20} color="#2563EB" />
                        </span>
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <Link href={`/${params?.lang ?? 'en-US'}/${item.button.link}`}>{item.button.title}</Link>
                </div>
              </div>
            </div>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Section4;
