'use client';

import { useTranslation } from '@/i18n/client';
import { useParams } from 'next/navigation';
import { Col, Container, Row } from 'react-bootstrap';
import { BiTimeFive } from 'react-icons/bi';
import { Locale } from '../../../i18n-config';
import LinkTo from '../common/LinkTo';

type Props = {
  onClick?: () => void;
};

function SiteCustomerSupportComponent(props: Props) {
  const params = useParams<{ lang?: string }>();
  const lang = params?.lang;
  const { t } = useTranslation((lang as Locale) || 'defaultLocale');

  return (
    <section className="customer-support bg-fifteenColor pb-4">
      <Container fluid={'xl'}>
        <Row className="">
          <Col className="d-flex flex-column justify-content-center align-content-center text-center align-items-center mt-5">
            <p className="text-white fs-5 font-sofiaSans">{t('home/SiteCustomerSupport/subTitle')}</p>
            <p className="fs-1 text-white font-sofiaSans fw-bold">{t('home/SiteCustomerSupport/title')}</p>
            <LinkTo
              href={`/${lang}/plans`}
              className="tw-bg-[#4167DA] tw-text-white hover:tw-bg-[#4167DA] hover:tw-text-white tw-px-[32px] tw-py-[14px] tw-rounded-lg"
              onClick={() => {
                props?.onClick && props?.onClick();
              }}
            >
              {t('home/SiteCustomerSupport/btn')}
            </LinkTo>
          </Col>
        </Row>
        <Row className="my-4">
          <Col className="tw-flex tw-items-center text-white font-sofiaSans lg:tw-gap-10 tw-gap-3 tw-justify-center">
            <div>
              <span className="font-sofiaSans fs-5 tw-flex tw-items-center tw-justify-center tw-gap-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.26044 23.0854L21.6397 2.39572L19.7387 0.798828L17.0989 3.94141H4C2.897 3.94141 2 4.83841 2 5.94141V17.9414C2 18.9356 2.7288 19.7625 3.68035 19.9159L2.35938 21.4885L4.26044 23.0854ZM5.33892 17.9414H4V11.9414H10.3789L5.33892 17.9414ZM13.7389 7.94141L15.4189 5.94141H4V7.94141H13.7389ZM20 7.94141H18.569L15.2646 11.9414H20.001L20.002 17.9414H10.3081L8.65592 19.9414H20C21.103 19.9414 22 19.0444 22 17.9414V5.94141C22 5.38304 21.7701 4.87746 21.4 4.51432L20 6.2091V7.94141Z"
                    fill="#889096"
                  />
                </svg>
                {t('home/SiteCustomerSupport/text-01')}
              </span>
            </div>
            <div>
              <span className="font-sofiaSans fs-5 tw-flex tw-items-center tw-justify-center tw-gap-1">
                <BiTimeFive className="fs-2 tw-text-neutral-400" />
                {t('home/SiteCustomerSupport/text-02')}
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default SiteCustomerSupportComponent;
